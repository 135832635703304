// angular
import { NgModule } from '@angular/core'
import { AppComponent } from './app.component'
import { HTTP_INTERCEPTORS } from '@angular/common/http'

// external libs
import 'moment/locale/de'

import { ErrorHandler, Injectable } from '@angular/core'
import * as Sentry from '@sentry/browser'

import { MainHttpInterceptor } from './misc/main-http-interceptor'

// import { ZimGMapComponent } from './component/zim-gmap/zim-gmap.component'
import { environment } from '@environments/environment'
import { SharedModule } from './shared.module'
import { DrawerService } from '@service/drawer.service'

import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

import { Angulartics2Module } from 'angulartics2'
import { ToastrModule } from 'ngx-toastr'
import { NgxLoadingModule } from 'ngx-loading'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateModule } from '@ngx-translate/core'
import { SentryService } from '@service/sentry.service'
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular'
import { BonusFaqComponent } from '@component/bonus-faq/bonus-faq.component'
import { AuthGuard } from './guard/auth.guard'

Sentry.init({
  dsn: environment.SentryDSN
})

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  constructor(
    private SentryService: SentryService
  ) { }

  handleError(error) {

    this.SentryService.silentCaptureException(error.originalError || error)

    // Sentry.showReportDialog({
    //   eventId,
    //   title:"Es ist ein technischer Fehler aufgetreten. Wir arbeiten hart um so etwas in Zukunft zu beheben.",
    //   subtitle:"Bitte laden sie die Seite neu und melden sich einmal an und ab. Tritt der Fehler weiter auf kontaktieren sie uns. Ein technischer Bericht des Fehlers wurde unserem Team geschickt.",
    //   subtitle2:""
    // })
  }
}

const routes: Routes = [
  {
    path: "",
    redirectTo: '/news',
    pathMatch: 'full'
  },
  {
    path: "businessprofileexternal/:id",
    loadChildren: () => import("./component/profile/company-profile-external/company-profile-external.component").then((m) => m.CompanyProfileExternalComponentModule),
  },
  {
    path: "profileexternal/:id",
    loadChildren: () => import("./component/profile/profile-external/profile-external.component").then((m) => m.ProfileExternalComponentModule),
  },
  {
    path: "home",
    loadChildren: () => import("./component/front-page/front-page.component").then((m) => m.FrontPageComponentModule),
  },
  {
    path: "news",
    loadChildren: () => import("./news.module").then((m) => m.NewsModule),
  },
  {
    path: "market",
    loadChildren: () => import("./advert.module").then((m) => m.AdvertModule),
  },
  {
    path: "job",
    loadChildren: () => import("./job.module").then((m) => m.JobModule),
  },
  {
    path: "blog",
    loadChildren: () => import("./blog.module").then((m) => m.BlogModule),
  },
  // {
  //   path: "wiki",
  //   loadChildren: () => import("./wiki.module").then((m) => m.WikiModule),
  // },
  {
    path: "grundwissen",
    loadChildren: () => import("./wiki.module").then((m) => m.WikiModule),
  },
  {
    path: "profile",
    loadChildren: () => import("./profile.module").then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: "partners",
    loadChildren: () => import("./component/partner/partner.component").then((m) => m.PartnerComponentModule),
  },
  {
    path: "peripherals",
    loadChildren: () => import("./peripheral.module").then((m) => m.PeripheralModule),
  },
  {
    path: "password-reset/:id/:token",
    loadChildren: () => import("./component/reset-password/reset-password.component").then((m) => m.ResetPasswordComponentModule),
  },
  {
    path: "password-reset-facebook/:id/:token",
    loadChildren: () => import("./component/reset-password-facebook/reset-password-facebook.component").then((m) => m.ResetPasswordFacebookComponentModule),
  },
  {
    path: "registration-confirm/:user_id/:confirmation_code",
    loadChildren: () => import("./component/registration-confirm/registration-confirm.component").then((m) => m.RegistrationConfirmModule),
  },
  {
    path: "sso",
    loadChildren: () => import("./sso.module").then((m) => m.SsoModule),
  },
  {
    path: 'boni/faq',
    component: BonusFaqComponent,
  },
  {
    path: 'business',
    loadChildren: () => import('./component/profile/company-profile-inquiry-page/company-profile-inquiry-page.component').then(m => m.CompanyProfileInquiryPageModule),
  },
  {
    path: '**',
    redirectTo: '/news',
  },
]

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    SharedModule,

    BrowserModule,
    BrowserAnimationsModule,

    TranslateModule.forRoot({}),
    RouterModule.forRoot(
      routes,
      {
        preloadingStrategy: PreloadAllModules,

        // this should in the future solve a lot of scroll issues
        // e.g. havong to scroll to the top of the page manually all the time
        // but right now its bugged https://github.com/angular/angular/issues/24547
        // scrollPositionRestoration: 'enabled'
      }
    ),
    Angulartics2Module.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyDnJASloVTDFdwCXxSruflAR6rsKvoqIfQ',
    //   libraries: ['places'],
    // }),
    NgxLoadingModule.forRoot({}),
  ],
  exports: [
    SharedModule,

    BrowserModule,
    BrowserAnimationsModule,

    TranslateModule,
    RouterModule,
    Angulartics2Module,
    ToastrModule,
    NgxLoadingModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: MainHttpInterceptor, multi: true },
    DrawerService,
    { provide: TINYMCE_SCRIPT_SRC, useValue: '/build/tinymce/tinymce.min.js' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
